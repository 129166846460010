import Splide from '@splidejs/splide';
import { gsap, MotionPathPlugin } from 'gsap/all';

(() => {
  gsap.registerPlugin(MotionPathPlugin);

  const sliderOptions = {
    perPage: 3,
    breakpoints: {
      1023: {
        perPage: 2,
      },
      639: {
        perPage: 1,
      },
    },
    gap: '2rem',
    pagination: false,
    lazyLoad: true,
    classes: {
      arrows: 'splide__arrows slide-arrows',
      arrow: 'splide__arrow slide-arrow',
      prev: 'splide__arrow--prev slide-prev',
      next: 'splide__arrow--next slide-next',
    },
  };
  document.querySelectorAll('.splide:not(.splide-specials)').forEach((elem) => {
    const { perPage } = elem.dataset;
    if (typeof perPage !== 'undefined') {
      sliderOptions.perPage = perPage;
    }
    const splide = new Splide(elem, sliderOptions);
    splide.mount();
  });

  function assignSlideDimensions(elements, container, deco) {
    let heightMax = 0;
    let width = 0;
    elements.forEach((elem) => {
      const { scrollHeight, scrollWidth } = elem;
      if (scrollHeight > heightMax) {
        heightMax = scrollHeight;
        width = scrollWidth;
      }
    });
    elements.forEach((elem) => { elem.style.height = `${heightMax}px`; });
    const lg = window.matchMedia('(min-width: 1024px)');
    if (lg.matches) {
      container.style.height = `${heightMax + 10}px`;
    } else {
      container.style.height = `${(heightMax * 2) + 10}px`;
    }
    if (deco) {
      deco.style.height = `${heightMax}px`;
      deco.style.width = `${width - 32}px`;
      if (lg.matches) {
        deco.style.top = 0;
      } else {
        deco.style.top = `${heightMax}px`;
      }
    }
  }

  function initAnimation(container) {
    container.querySelectorAll('img[data-path]').forEach((img) => {
      const { path, duration, ease } = img.dataset;
      const animConfig = {
        duration,
        motionPath: {
          path,
          alignOrigin: [0.5, 0.5],
        },
        repeat: -1,
        transformOrigin: '50% 50%',
        ease,
      };
      gsap.to(img, animConfig);
    });
  }

  const $splideSpecials = document.querySelector('.splide-specials');
  let $splideContainer = null;
  let $slideCols = null;
  let $decoContainer = null;
  let splideSpecial = null;

  if ($splideSpecials) {
    $splideContainer = $splideSpecials.closest('.container');
    $decoContainer = document.querySelector('.deco-container');
    $slideCols = $splideSpecials.querySelectorAll('.splide__slide > div > div');
    splideSpecial = new Splide($splideSpecials, {
      perPage: 1,
      type: 'fade',
      lazyLoad: false,
      arrows: false,
      autoplay: true,
      speed: 1000,
      interval: 5000,
      rewind: true,
    });

    splideSpecial.on('mounted', () => {
      assignSlideDimensions($slideCols, $splideContainer, $decoContainer);
    });

    splideSpecial.on('refresh', () => {
      assignSlideDimensions($slideCols, $splideContainer, $decoContainer);
    });

    // images loaded callback (https://stackoverflow.com/questions/11071314/javascript-execute-after-all-images-have-loaded)
    Promise.all(
      Array.from($splideSpecials.querySelectorAll('img.special-image'))
        .filter((img) => !img.complete)
        .map((img) => new Promise((resolve) => {
          img.onload = resolve;
          img.onerror = resolve;
        })),
    )
      .then(() => {
        splideSpecial.mount();
      });
    if ($decoContainer) {
      initAnimation($decoContainer);
    }
  }

  let resizeTimer;
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      if (splideSpecial) {
        splideSpecial.refresh();
      }
    }, 200);
  });
})();
