import 'tailwindcss/base.css';
import 'tailwindcss/components.css';
import './main.css';
import 'tailwindcss/utilities.css';

import '@splidejs/splide/dist/css/splide-core.min.css';
import 'glightbox/dist/css/glightbox.css';

import './scripts/defauts';
import './scripts/sliders';

import 'intersection-observer';
import GLightbox from 'glightbox';

// eslint-disable-next-line no-unused-vars
const lightbox = GLightbox();
