import GLightbox from 'glightbox';

(() => {
  const bodyObserver = new IntersectionObserver((cb) => {
    if (cb[0].isIntersecting) {
      document.body.classList.remove('entered');
    } else {
      document.body.classList.add('entered');
    }
  });
  bodyObserver.observe(document.querySelector('.body-observer'));

  function scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  document.querySelectorAll('.scroll-top').forEach((elem) => {
    elem.addEventListener('click', scrollTop);
  });

  function toggleAccordion() {
    this.classList.toggle('open');
    let accordionContent = this.nextSibling;
    if (accordionContent.nodeType !== 1) {
      accordionContent = accordionContent.nextSibling;
    }
    if (this.classList.contains('open')) {
      const contentHeight = accordionContent.scrollHeight;
      accordionContent.style.maxHeight = `${contentHeight}px`;
      accordionContent.classList.add('open');
    } else {
      accordionContent.style.maxHeight = '0px';
      accordionContent.classList.remove('open');
    }
  }

  function registerAccordions() {
    document.querySelectorAll('.accordion-title').forEach((elem) => {
      elem.addEventListener('click', toggleAccordion);
    });
  }

  registerAccordions();

  if (window.htmx) {
    window.htmx.on('htmx:afterSwap', () => {
      registerAccordions();
    });
  }

  const $mobileNav = document.querySelector('nav.mobile-nav');
  const $subMenus = $mobileNav.querySelectorAll('.accordion-title, .accordion-content');
  const $burgers = document.querySelectorAll('.menu-burger');

  function resetMobileNav() {
    document.body.style.height = null;
    document.body.style.overflow = null;
    $mobileNav.classList.remove('open');
    $subMenus.forEach((elem) => {
      elem.classList.remove('open');
      if (elem.classList.contains('accordion-content')) {
        elem.style.maxHeight = '0px';
      }
    });
  }

  function toggleMobileNav() {
    $burgers.forEach((elem) => {
      elem.classList.toggle('mobile-nav-visible');
    });
    if (this.classList.contains('mobile-nav-visible')) {
      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';
      $mobileNav.classList.add('open');
    } else {
      resetMobileNav();
    }
  }

  $burgers.forEach((elem) => {
    elem.addEventListener('click', toggleMobileNav);
  });

  let resizeTimer;
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      resetMobileNav();
      $burgers.forEach((elem) => {
        elem.classList.remove('mobile-nav-visible');
      });
      document.querySelectorAll('.accordion-title.open').forEach((elem) => {
        const next = elem.nextElementSibling;
        next.style.maxHeight = `${next.scrollHeight}px`;
      });
    }, 200);
  });
})();
